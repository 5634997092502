import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Title from '../main/Title';
import Sw from '../btn/Switch';
import globalLib from '../../utils';
import Sk from '../main/Sk';

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            user_id: this.props.user_id
        }
    }

    componentDidMount() {

        globalLib.restApi({ method: 'get', uri: 'users/' + this.state.user_id })
            .then(result => {
                this.setState({ user: result.data.info });
            })
            .catch(err => {
                //this.setState({processing: false});
                globalLib.checkResponseError(err)
            })


    }

    onLock = (object) => {
        if(object.value){
           this.props.socket.emit('lock_user', object.id);
        }
    }
    
    render() {
        if(!this.state.user)
        return (
            <>
                <Title text="Đang nạp dữ liệu ...."/>
                <Sk />
            </>
        );
        
        return (
            <Title
                text = {<Link to={`/customers/keywords/${this.state.user.phone}`}>{this.state.user.name} - {this.state.user.phone}</Link>}
                tool={
                <span className="tools">
                    

                    <Sw key={`S-${this.state.user_id}`}
                        code={this.state.user_id}
                        apiObj="users"
                        colName="status"
                        label="Block"
                        status={this.state.user.status === 'I' ? true : false}
                        click={globalLib.switchStatus} 
                        callBack = {this.onLock}/>

                    <Sw key={`W-${this.state.user_id}`}
                        code={this.state.user_id}
                        apiObj="users"
                        colName="warning"
                        label="Robot"
                        status={this.state.user.warning === 'R' ? true : false}
                        click={globalLib.switchStatus}
                         />
                </span>
            } />
        );
    }
}

const mapStateToProps = state => ({
    socket: state.socket.socket
});

export default connect(mapStateToProps)(User);