import React from 'react';
import { Link } from "react-router-dom";
import { MENU } from '../../const/menu';

const getMenu = function(items = MENU){
    return <ul>
        {
            items.map((item, index) => {
                return <li key={index}>
                    <Link to={item.path}>{item.title}</Link>
                    { 
                        item.child &&
                        getMenu(item.child)
                    }    
                </li>
            })
        }
    </ul>   
}
const Menu = function(){
    return  getMenu();
}
export default Menu;