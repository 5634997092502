import React, { Component } from 'react';
import { connect } from 'react-redux';
import { relatedProperties, clearProperties } from '../../redux/actions/property';
// import '../../style/product.css';
import Item from './Item';
import Sk from '../main/Sk';
import Paging from '../paging';

class Relation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.user_id,
      page: 1,
      properties: null
    }
  }

  pageClick = (page) => {
    let user_id  = this.state.user_id;
    let page_num = page;
    this.setState({page, properties: null}, 
      () => {
          this.props.relatedProperties(user_id, page_num)
      }
    );
  }

  componentDidMount() {
     this.props.relatedProperties(this.state.user_id, 1);
  }

  componentWillUnmount(){
      this.props.clearProperties();
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.properties!==prevState.properties){
       if(nextProps.properties){
          return {
              properties: nextProps.properties
          }
       }
    }
    return null;
  }

  showItems = () => {
    if (!this.state.properties) 
        return <Sk />;

    return this.state.properties.items.map((item, index) => {
        item.img_url = this.state.properties.img_url;
        return <Item key={index} data={item} />
    });
  }

  render() {
    return (
      <div className="products">
        <ul>
          {this.showItems()}
        </ul>
        {
          this.state.properties &&
          (
                <Paging key="related-property-paging"
                pageClick = {this.pageClick}
                total={this.state.properties.total}
                page={this.state.page}
                page_size={20} />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
    properties: state.property.related_properties
});

const mapDispatchToProps = dispatch => ({
    relatedProperties: (user_id, page) => dispatch(relatedProperties(user_id, page)),
    clearProperties: () => dispatch(clearProperties())
});

export default connect(mapStateToProps, mapDispatchToProps)(Relation);