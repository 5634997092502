import React, { Component } from 'react';
import QueryString from 'query-string';

import Paging from '../paging';
import Head from './Head';
import Title from '../main/Title';
import Sk from "../main/Sk";
import Search from '../property/Search';
import globalLib from '../../utils';
import Item from './Item';

class Customers extends Component {
    constructor(props) {
        super(props);
        let params = QueryString.parse(this.props.location.search);
        let page = params.page ? parseInt(params.page) : 1;

        this.state = {
            items: null,
            status: this.props.match.params.status,
            reload: false,
            counter: 0,
            page: page,
            keywords: this.props.match.params.keywords,
            search: false,
            total: 0
        }
    }

    render() {

        return (
            <main>
                <Title key="title-links" text={`Danh Sách Link`} tool={
                    <Search key="search-links"
                    onSearch = {this.onSearch} />} />
                {
                    this.state.items ?
                        (
                            <div className="content">
                                <table>
                                    <Head />
                                    <tbody>
                                        {this.showItems()}
                                        
                                    </tbody>
                                </table>
                                <Paging key = {`links-paging`}
                                    total = {this.state.total}
                                    page = {this.state.search ? 1 : this.state.page}
                                    page_size = {20}
                                    uri = {`links`} />
                            </div>
                        )
                        :
                        <Sk />
                }

            </main>
        );
    }

    Load = () => {
        this.setState({ items: null, reload: false }, () => this.getLinks());
    }

    onSearch = (params) => {
        this.setState({ search_params: params, search: true, items: null, reload: false },
            () => this.getLinks());
    }

    onDelete = (id) => {
        console.log('Truoc', this.state.items);
        let items = this.state.items.filter((item) => {
            return item.id!==id;
        });
        this.setState({items}, () => {
            console.log('Sau', this.state.items);
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let kw = nextProps.match.params.keywords;
        if (kw && kw !== prevState.keywords) {
            return { keywords: kw, reload: true, items: null }
        }

        let params = QueryString.parse(nextProps.location.search);
        let page = params.page ? parseInt(params.page) : 1;

        if (page !== prevState.page) {
            return { page, reload: true, items: null }
        }

        return null;
    }

    componentDidUpdate() {
        if (this.state.reload) {
            this.Load();
        }
    }

    componentDidMount() {
        this.getLinks();
    }

    showItems = () => {
        return this.state.items.map((item) => {
            item.img_url = this.state.img_url;
            return <Item key={item.id} data={item} />
        });
    }

    getLinks = () => {

        let data = {...this.state.search_params,
            page: this.state.search ? 1 : this.state.page
        }
        
        globalLib.restApi({ method: 'post', uri: 'links', data })
            .then(result => {
                this.setState({ total: result.data.total, items: result.data.items, img_url: result.data.img_url, reload: false, counter: 0 });
                if (this.state.search) {
                    this.props.location.search = {};
                    this.setState({ search: false, page: 1 });
                    let uri = '/links';
                    if (this.state.status) {
                        uri += '/' + this.state.status;
                    }
                    window.history.pushState({}, null, uri);
                }
            })
            .catch(err => {
                globalLib.checkResponseError(err);
                this.setState({ items: [], reload: false });
            });
    }
}

export default Customers;