export const LIST_ARTICLE_CATEGORIES = 'LIST_ARTICLE_CATEGORIES';
export const LIST_CATEGORIES = 'LIST_CATEGORIES';
export const LIST_CITIES = 'LIST_CITIES';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CLEAR_RELATED_PROPERTIES = 'CLEAR_RELATED_PROPERTIES';
export const RELATED_PROPERTIES = 'RELATED_PROPERTIES';
export const INFO_PROPERTY = 'INFO_PROPERTY';
export const STORE_SOCKET = 'STORE_SOCKET';
export const COUNT_USERS = 'COUNT_USERS';
export const ONLINE_USERS = 'ONLINE_USERS';
export const HIDE_SOCKET_BAR = 'HIDE_SOCKET_BAR';