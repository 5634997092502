import {LIST_CATEGORIES, LIST_ARTICLE_CATEGORIES} from '../../const';

const  categoryReducer = (state={}, action) => {
    switch (action.type) {
      case LIST_CATEGORIES:
        return {...state, categories: action.categories}
      case LIST_ARTICLE_CATEGORIES:  
        return {...state, article_categories: action.article_categories}
      default: 
        return state
    }
}
export default  categoryReducer;