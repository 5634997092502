import React, { Component } from 'react'
import { connect } from 'react-redux'

import jwt from 'jsonwebtoken';
import { userLogout } from '../../redux/actions/login';

const ProtectRoute = function (ComposedComponent) {
  class Authentication extends Component {

    componentDidMount() {
      let user = jwt.decode(this.props.token);
      const time = Date.now() / 1000;
      if (user.exp < time) {
         this.props.userLogout();
      }
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }

  const mapStateToProps = (state) => ({
    login: state.login.isLogin,
    token: state.login.token
  })

  const mapDispatchToProps = dispatch => ({
    userLogout: () => {
      return dispatch(userLogout());
    }
  })

  return connect(mapStateToProps, mapDispatchToProps)(Authentication)
}

export default ProtectRoute;