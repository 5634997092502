import React from 'react';

import { connect } from 'react-redux';
import { listArticleCategories, article_categories } from '../../redux/actions/category';

import Head from './Head';
import Base from './Base';

import Item from './Item';
import Title from '../main/Title';
import Sk from '../main/Sk';

class Category extends Base {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.categories, 
            reload: false,
            isOpen: false,
            update: false,
            item: null,
            processing: false
        }
    }

    process = () => {
        return <Sk />
    }

    title = () => {
        return (
            <Title key="title-users" 
                text="Danh mục bài viết" 
                tool = {
                    <span className="tools">
                        <button className="add" onClick={ () => this.add() }>
                            Add New
                        </button>
                    </span>
                }
            />
        )
        
    }

    list = () => {

        return this.state.items.map((item, index) => {
            return <Item key={index} data={item} edit = { this.edit }  callBack={this.store}/>
        });
    }

    store = (result) => {
        //this.getCategories();
        let items = this.state.items;
        let objIndex = items.findIndex((obj => obj.id === result.id));
        items[objIndex].status = (result.value?'A':'I');
        this.setState({items});
        this.saveCategories(items);
    }

    form = () => {
       
        return (
            <div id="myModal" className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <span className="close" onClick={ () => this.cancel() }>&times;</span>
                        <h2>Edit category</h2>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div>
                                <label>Category Name</label>
                                <input type="text" id="name" onChange={(e) => this.handleChange(e) } defaultValue={this.state.item.name}/>
                            </div>
                            <div className='a-right'>
                                {
                                    this.state.processing?
                                    this.process()
                                    :
                                    <button onClick={ (e) => this.save(e) }>Save</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    } 

    render() {

        return (
            <main>

                {
                    this.state.update &&
                    this.form()
                }

                {
                    this.title()
                }

                {
                    this.state.items ?
                        (
                            <div className="content">
                                <table>
                                    <Head />
                                    <tbody>
                                        {this.list()}
                                    </tbody>
                                </table>
                            </div>
                        )
                        :
                        this.process()
                }

            </main>
        );
    }

    

    getCategories = () => {
        this.props.getArticleCategories();
    }

    saveCategories = (items) => {
        this.props.saveCategories(items);
    }
}


const mapStateToProps = (state) => ({
    categories: state.category.article_categories
});

const mapDistpatchToProps = (dispatch) => ({
    getArticleCategories: () => dispatch(listArticleCategories()),
    saveCategories: (items) => dispatch(article_categories(items))
});

export default connect(mapStateToProps, mapDistpatchToProps)(Category);