import React, { Component } from 'react';
// import '../../style/search.css';

class SearchKey extends Component {

    render() {
        return (
            <div className="search">
                <input type="text" id="keywords"
                    onKeyUp ={e => this.props.onEnter(e)}
                    onChange={e => this.props.onChange(e)}
                    value={this.props.defaultValue} 
                    placeholder = "Keywords ..."/>
                <button onClick={(e) => this.props.reset(e)}> Reset </button>
                <button onClick={(e) => this.props.refresh(e)}> Refresh </button>
            </div>
        );
    }
}

export default SearchKey;