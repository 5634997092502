import React, { Component } from 'react';

class Head extends Component {
    render() {
        return (
           <thead>
            <tr>
                <th className="w-50 a-center">ID</th>
                <th className="w-200 a-center">Hinh</th>
                <th>Description</th>           
                <th className="w-60 a-center">Active</th>
                <th className="w-60 a-center">Delete</th>
                <th className="w-60 a-center">Edit</th>
            </tr>
            </thead> 
        );
    }
}

export default Head;