const  propertyReducer = (state={}, action) => {
    switch (action.type) {
      case 'RELATED_PROPERTIES':
        return {...state, related_properties: action.properties}
      case 'CLEAR_RELATED_PROPERTIES':
        return {...state, related_properties: null}  
      case 'INFO_PROPERTY':
        return {...state, info_property: action.info_property}
          
      default: 
        return state
    }
}
export default  propertyReducer;