import React from 'react';
import Logout from '../auth/Logout';
import Menu from './Menu';

const Nav = function() {
    return (
        <nav>
            <Logout/>
            <Menu/>
        </nav>
    );
}
export default Nav;