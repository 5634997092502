import React, { Component } from 'react';

// import '../../style/global.css';
// import '../../style/main.css';

import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";

import ProtectComponent from '../auth/ProtectRoute';

import Header from '../header';
import Home from '../home';
import Properties from '../property';
import Customers from '../customer';
import PropertyDetail from '../property_detail';
import OnlineUsers from '../sidebar/OnlineUsers';
import Cache from '../cache';
import Articles from '../article';
import AddArticle from '../article/Add';
import EditArticle from '../article/Edit';
import Categories from '../category';
import Links from '../links';


class Main extends Component {

    render() {
        return (
            <Router>
                <Header />
                <section className="main">
                    
                    <div className="rightSidebar">

                        <Switch>
                            <Route path="/" exact component={ProtectComponent(Home)} />
                            <Route path="/properties" component={ProtectComponent(Properties)} exact />
                            <Route path="/properties/customer/:customer_id" component={ProtectComponent(Properties)} exact />
                            <Route path="/properties/:status" exact component={ProtectComponent(Properties)}
                            // render = {props => <Properties {...props}/>}
                            />
                            <Route path="/properties/:id/:user_id" component={ProtectComponent(PropertyDetail)}
                            // render = {props => PrivateRoute(PropertyDetail) {...props}/>}
                            />
                            <Route path="/customers" exact component={ProtectComponent(Customers)} />
                            <Route path="/customers/keywords/:keywords" exact component={ProtectComponent(Customers)}/>
                            <Route path="/customers/:status" component={ProtectComponent(Customers)}
                            // render={
                            //     props => <Customers {...props}/>} 
                            />

                            <Route path="/articles" exact component={ProtectComponent(Articles)} />
                            <Route path="/articles/add" exact component={ProtectComponent(AddArticle)} />
                            <Route path="/articles/edit/:id" component={ProtectComponent(EditArticle)} />
                            <Route path="/categories" exact component={ProtectComponent(Categories)} />
                            <Route path="/links" exact component={ProtectComponent(Links)} />
                            

                            <Route path="/cache" exact component={ProtectComponent(Cache)} />

                            <Route component={() => (<h1>Not found</h1>)} />

                        </Switch>
                    </div>
                    <OnlineUsers/>
                </section>
            </Router>
        )
    }
}

export default Main;