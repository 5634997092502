import {STORE_SOCKET, COUNT_USERS, ONLINE_USERS, HIDE_SOCKET_BAR} from '../../const';
export const storeSocket = socket => ({
  type: STORE_SOCKET,
  socket
});

export const userCounter = counter => ({
  type: COUNT_USERS,
  counter
});

export const onlineUsers = users => ({
  type: ONLINE_USERS,
  online_users: users
});

export const hideSocketbar = (status=false) => ({
  type: HIDE_SOCKET_BAR,
  status
});