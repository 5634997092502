const loginReducer = (state = {token:''}, action) => {
    
    switch (action.type) {
      case 'LOGIN_USER':
        return {...state, token: action.token, isLogin: action.isLogin}
      case 'LOGOUT_USER':{
          return {...state, token: '', isLogin: false, counter: 0, online_users: null}
        }
      default:
        return state
    }
}
export default loginReducer;