import React, { Component } from 'react';
import {Link} from 'react-router-dom';

// import '../../style/paging.css';

class Paging extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: this.props.total?this.props.total:0,
            page_items: this.props.page_items?this.props.page_items:3,
            page_sise: this.props.page_size?this.props.page_size:20,
            current_page: this.props.page?this.props.page:1,
            uri: this.props.uri
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.page!==prevState.current_page){
            return {current_page : nextProps.page};
        }
        return null;
    }

    renderPages = () => {
        
        let total_pages = Math.ceil(this.state.total/this.state.page_sise);
        if(total_pages <= 1) return;
        let items = [];
        let min = this.state.current_page - this.state.page_items;
        min = (min < 1) ? 1:min;
        let max = this.state.current_page + this.state.page_items;
        max = (max > total_pages) ? total_pages : max;
        for(let i=min; i<=max; i++){
            
            if(i === this.state.current_page){
                items.push(<strong key={i}>{i}</strong>);
            }
            else{
                if(this.props.pageClick){
                    items.push(<button key={i} onClick={() => this.props.pageClick(i)}>{i}</button>)
                } 
                else{
                    items.push(<Link key={i} to={`/${this.state.uri}?page=${i}`}>{i}</Link>);
                }
            }
            
        }
        return items;
    }
    
    render() {
        if(this.state.total <= this.state.page_items) return null;
        return (
            <div id="paginator">
                {
                    this.renderPages()
                }
            </div>
        );
    }
}

export default Paging;