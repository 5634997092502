import React, { Component } from 'react';
import QueryString from 'query-string';

// import '../../style/list.css';
import Sk from '../main/Sk';
import Item from './Item';
import Head from './Head';
import Title from '../main/Title';
import Paging from '../paging';
import Search from './Search';
import globalLib from '../../utils';
import * as PRO from '../../const/property';

class Properties extends Component {
    constructor(props) {
        super(props);

        let params = QueryString.parse(this.props.location.search);
        let page   = params.page ? parseInt(params.page) : 1;

        this.state = {
            items: null,
            img_url: null,
            reload: false,
            page,
            total: 0,
            search: false,
            status: this.props.match.params.status,
            customer_id: this.props.match.params.customer_id,
            search_params:{}
        }
    }

    onSearch = (params) => {
        this.setState({ search_params: params, search: true, items: null, reload: false },
            () => this.getProperties());
    }

    getProperties = () => {
        let uri = 'properties';
        let data = {...this.state.search_params,
            status: this.state.status,
            page: this.state.search ? 1 : this.state.page,
            user_id: this.state.customer_id
        }

        globalLib.restApi({ method: 'post', uri, data })
            .then(result => {
                this.setState({ total: result.data.total, items: result.data.items, img_url: result.data.img_url })
                if (this.state.search) {
                    this.props.location.search = {};
                    this.setState({ search: false, page: 1 });
                    let uri = '/properties';
                    if (this.state.status) {
                        uri += '/' + this.state.status;
                    }
                    window.history.pushState({}, null, uri);
                }
            })
            .catch(err => {
                this.setState({ items: [], reload: false });
                globalLib.checkResponseError(err);
            });
    }

    showItems = () => {
        return this.state.items.map((item, index) => {
            item.img_url = this.state.img_url;
            return <Item key={index} data={item} />
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let st = nextProps.match.params.status;
        if (st !== prevState.status) {
            return { status: st, reload: true, items: null }
        }
        let ct = nextProps.match.params.customer_id;
        if (ct !== prevState.customer_id) {
            return { customer_id: ct, reload: true, items: null }
        }

        let params = QueryString.parse(nextProps.location.search);
        let page = params.page ? parseInt(params.page) : 1;

        if (page !== prevState.page) {
            return { page, reload: true, items: null }
        }
        return null;
    }

    componentDidMount() {
        this.getProperties();
    }

    componentDidUpdate() {
        if (this.state.reload) {
            this.Load();
        }
    }

    Load = () => {
        this.setState({ reload: false, items: null }, () => this.getProperties());
    }

    render() {

        let title = PRO.TITLE[this.state.status]?PRO.TITLE[this.state.status]: PRO.TITLE_DEFAULT;

        return (
            <main>
                <Title key="title-properties" text={title} tool={
                    <Search key="search-properties"
                        defaultValue={this.state.keywords} 
                        onSearch = {this.onSearch}/>} />

                {
                    !this.state.items ?
                        <Sk />
                        :
                        (
                            <div className="content">
                                <table>
                                    <Head />
                                    <tbody>
                                        {this.showItems()}
                                    </tbody>
                                </table>
                                <Paging key={`property-paging`}
                                    total={this.state.total}
                                    page={this.state.search ? 1 : this.state.page}
                                    page_size={20}
                                    uri={`properties` + (this.state.status ? `/${this.state.status}` : (this.state.customer_id ? `/customer/${this.state.customer_id}` : ``))} />
                            </div>
                        )

                }

            </main>
        );
    }


}

export default Properties;