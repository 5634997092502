import React, { Component } from 'react';

class Head extends Component {
    render() {
        return (
           <thead>
            <tr>
                <th className="w-50 a-center">ID</th>
                <th>Name</th>
                <th className="w-80">Phone</th>
                <th>Email</th>
                <th>Last Login</th>
                <th>Ip</th>
                <th className="w-60 a-center">Robot</th>             
                <th className="w-60 a-center">Blocked</th>
                <th className="w-40 a-center">Edit</th>
                <th className="w-40 a-center">Clear</th>
            </tr>
            </thead> 
        );
    }
}

export default Head;