import React, { Component } from 'react';
import { connect } from 'react-redux';

//import Action from '../btn/Action';
import Sw from '../btn/Switch';
import globalLib from '../../utils';
import { Link } from 'react-router-dom';

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = { edit: false, data: this.props.data }
  }


  edit = () => {
    this.setState({
      edit: true,
      name: this.state.data.name,
      phone: this.state.data.phone,
      email: this.state.data.email,
      id: this.state.data.user_id
    });

  }

  cancel = () => {
    this.setState({ edit: false });
  }

  save = () => {
    let data = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      user_id: this.state.id
    }
    globalLib.restApi({ method: 'patch', uri: 'users/edit', data })
      .then(result => {
        let info = { ...this.state.data, name: data.name, phone: data.phone, email: data.email }
        this.setState({ data: info });
        alert('Save successfully.');
      })
      .catch(err => {
         globalLib.checkResponseError(err);
      })
  }

  delete = () => {

  }

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  }

  clean = () => {
    let id = this.state.data.user_id;
    if (window.confirm('Are you sure you want to clear properties of user ' + id + '?')) {
      globalLib.restApi({ method: 'patch', uri: 'cache/clear-user-properties', data: { id } })
        .then(result => {
          this.props.socket.emit('lock_user', id);
          alert('Clear user '+id+' properties successfully');
        })
        .catch(err => {
          globalLib.checkResponseError(err);
        })
    }
  }
  render() {
    let data = this.state.data;
    if (this.state.edit)
      return (
        <tr>
          <td>{data.user_id}</td>
          <td className="text-cap">
            <input type="text" id="name" defaultValue={this.state.name} onChange={(e) => this.handleChange(e)} />
          </td>
          <td>
            <input type="text" id="phone" defaultValue={this.state.phone} />
          </td>
          <td><input type="text" id="email" defaultValue={this.state.email} /> </td>
          <td>{data.last_logged_in}</td>
          <td>{data.ip}</td>
          <td>
            <Sw key={`W-${data.user_id}`}
              code={data.user_id}
              apiObj="users"
              colName="warning"
              status={data.warning === 'R' ? true : false}
              click={globalLib.switchStatus} />
          </td>

          <td>
            <Sw key={`S-${data.user_id}`}
              code={data.user_id}
              apiObj="users"
              colName="status"
              status={data.status === 'I' ? true : false}
              click={globalLib.switchStatus}
              callBack={() => this.props.callBack('AAAAAA') }
            />
          </td>
          <td>
            <button className="btnEdit" onClick={() => this.save()}>Save</button>
          </td>
          <td>
            <button className="btnEdit" onClick={() => this.cancel()}>Close</button>
          </td>
        </tr>
      )
    else
      return (
        <tr>
          <td>{data.user_id}</td>
          <td className="text-cap">
            <Link to={`/properties/customer/${data.user_id}`}>
              {data.name}
            </Link>
          </td>
          <td>
            <Link to={`/customers/keywords/${data.phone}`}>{data.phone}</Link>
          </td>
          <td>{data.email}</td>
          <td>{data.last_logged_in}</td>
          <td>{data.ip}</td>

          <td>
            <Sw key={`W-${data.user_id}`}
              code={data.user_id}
              apiObj="users"
              colName="warning"
              status={data.warning === 'R' ? true : false}
              click={globalLib.switchStatus} />
          </td>

          <td>
            <Sw key={`S-${data.user_id}`}
              code={data.user_id}
              apiObj="users"
              colName="status"
              status={data.status === 'I' ? true : false}
              click={globalLib.switchStatus}
              callBack={this.props.callBack}
            />
          </td>
          <td>
            <button className="btnEdit" onClick={() => this.edit()}>Edit</button>
          </td>
          <td>
            <button className="btnEdit" onClick={() => this.clean()}>Empty</button>
          </td>


        </tr>
      )

  }
}


const mapStateToProps = state => ({
  socket: state.socket.socket
});

export default connect(mapStateToProps)(Item);