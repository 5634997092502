import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import loginReducer from './login';
import propertyReducer from './property';
import locationReducer from './location';
import categoryReducer from './category';
import socketReducer from './socket';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['login', 'location', 'category']
}

const rootReducer = combineReducers({
    login   : loginReducer,
    property: propertyReducer,
    location: locationReducer,
    category: categoryReducer,
    socket: socketReducer
});

export default persistReducer(persistConfig, rootReducer);

//export default rootReducer;