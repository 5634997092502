import React, { Component } from 'react';
import {connect} from 'react-redux';
import {infoProperty, related_properties} from '../../redux/actions/property';

import globalLib from '../../utils';

// import '../../style/detail.css';
import Sw from '../btn/Switch';
import Picture from './Picture';
import Sk from '../main/Sk';

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uri: 'properties',
            info: null,
            id: this.props.id,
            reload: false
        }
    }

    componentDidMount() {
        this.props.infoProperty(this.state.id);
    }

    callBack = (object) => {
        let status = null;
        if(object.column === 'deleted'){
            status = (object.value)?'Đã xóa':'Đã phục hồi';
        }
        else{
            status = (object.value)?'Không duyệt':'Đã duyệt';
        }
        
        let gui    = (object.value)?'false':'pass';

        let properties = this.props.related_properties.items;
        let index = properties.findIndex(element => parseInt(element.id) === parseInt(this.state.id) );
        properties[index] = {...properties[index], status, gui}
        let new_properties = {...this.props.related_properties, items: properties};
        this.props.updateRelatedProperties(new_properties);
    }

    render() {
        let info = this.props.info;
        if (!info) 
        return (
            <article>
               <Sk />
            </article>
        )


        return (

            <article>
                <hgroup>
                    <span>
                        <label>ID</label>{info.property_id}
                    </span>
                    <span>
                        <Sw key={`P-${info.property_id}`}
                            code={info.property_id}
                            apiObj="properties"
                            colName="public"
                            label="Xấu"
                            status={info.public==='I'?true:false}
                            click={globalLib.switchStatus}
                        />
                    
                        <Sw key={`S-${info.property_id}`}
                            code={info.property_id}
                            apiObj="properties"
                            colName="status"
                            label="Chặn"
                            status={info.status_id==='3'?true:false}
                            click={globalLib.switchStatus}
                            callBack={this.callBack}
                        />
                   
                        <Sw key={`D-${info.property_id}`}
                            code={info.property_id}
                            apiObj="properties"
                            colName="deleted"
                            label="Xóa"
                            status={info.deleted==='Y'?true:false}
                            click={globalLib.switchStatus}
                            callBack={this.callBack}
                        />
                    </span>

                    {/* <span><Switch label="Delete" /></span>
                    <span><Switch label="Home" /></span> */}

                </hgroup>
                <h1>
                    {info.title}
                </h1>
                <div className="info">
                    <span>{info.price_description}</span>
                    <span>{info.dtsd}m²</span> <em>{info.district}, {info.city}</em>
                </div>

                <Picture key={`Pic` + info.property_id} id={info.property_id} />

                <div className="detail">
                    {info.description.split('<br />').join('\n')}
                </div>
                <h2>Đặc điểm</h2>
                <ul>
                    <li>
                        <label>Loại tài sản </label>
                        <strong> {info.category} </strong>
                    </li>
                    <li>
                        <label>Diện tích </label>
                        <strong>
                            <span>{info.dtsd}m²</span>
                        </strong>
                        <em />
                    </li>
                    <li>
                        <label>Địa chỉ </label>
                        <strong> {info.district}, {info.city} </strong>
                    </li>
                    <li>
                        <label>Vị trí </label>
                        <strong> {info.position} </strong>
                    </li>
                    <li>
                        <label>Hướng </label> <strong> {info.direction} </strong>
                    </li>
                    <li>
                        <label>Pháp lý </label> <strong> {info.legal} </strong>
                    </li>
                </ul>

                <div className="date">Ngày sửa {info.modify_date} - Ngày đăng {info.posted_date}</div>
            </article>

        );
    }
}



const mapStateToProps = state => ({
    info: state.property.info_property,
    related_properties: state.property.related_properties
});

const mapDispatchToProps = dispatch => ({
    infoProperty: property_id => dispatch(infoProperty(property_id)),
    updateRelatedProperties: new_properties => dispatch(related_properties(new_properties))
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);