import React, { Component } from 'react';
import Title from '../main/Title';
import Form from './Form';
import globalLib from '../../utils';
import Sk from '../main/Sk';

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
        }
    }

    componentDidMount() {
        globalLib.restApi({ method: 'get', uri: 'articles/'+this.state.id })
        .then(result => {
            console.log(result.data.info);
            this.setState({ article: result.data.info, img_url: result.data.img_url});
            
        })
        .catch(err => {
            globalLib.checkResponseError(err);
            this.setState({ info: null });
        });
    }

    render() {
        return (
            <main>
                <Title text="Edit article" />
                {
                    this.state.article?
                    <Form article={this.state.article}/>
                    : 
                    <Sk />
                }
            </main>
        );
    }  
}

export default Edit;