import globalLib from '../../utils';
import {LIST_CATEGORIES, LIST_ARTICLE_CATEGORIES} from '../../const';

export const categories = categories => {
  return {
    type: LIST_CATEGORIES,
    categories
  }
}

export const listCategories = () => {
  return dispatch => {
    globalLib.restApi({ method: 'get', uri: 'location/categories' })
      .then(result => {
          dispatch(categories(result.data));
      })
      .catch(err => {
          console.log('Error: ', err);
      });
  };
}


export const article_categories = article_categories => {
  return {
    type: LIST_ARTICLE_CATEGORIES,
    article_categories
  }
}

export const listArticleCategories = () => {
  return dispatch => {
    globalLib.restApi({ method: 'get', uri: 'categories' })
      .then(result => {
          dispatch(article_categories(result.data.items));
      })
      .catch(err => {
          console.log('Error: ', err);
      });
  };
}