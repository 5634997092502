import React, { Component } from 'react';
//import { connect } from 'react-redux';


class Home extends Component {
    // componentDidMount(){
    //     const socket = this.props.socket;
    //     const token  = `Bearer ${this.props.token}`;
    //     if(socket){
    //         socket.emit('admin_connect', {token, msg: 'Hello'});
    //         socket.on('server_reply_admin', data => console.log(data));
    //     }
    // }
    render() {
        return (
            <main>
                <h1>Home page</h1>
            </main>
        );
    }
}

// const mapStateToProps = state => ({
//     socket: state.socket.socket,
//     token: state.login.token
// });

// export default connect(mapStateToProps)(Home);

export default Home;