import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.data
    }
  }
  
  render() {
    if (!this.state.item) return '';
    let item = this.state.item;
    return (
      <tr>
        <td>{item.id}</td>
        <td>
          <Link to={`/links/${item.id}`}>
            {item.description}
          </Link>
        </td>
        <td>
          {item.category}
        </td>
        <td>
          {item.city}
        </td>
        <td>
          {item.district}
        </td>
        <td>
          {item.ward}
        </td>
        <td>
          {item.total}
        </td>
        <td>
          <Link to={`/links/edit/${item.id}`}>
              <button className="btnEdit">Edit</button>
          </Link>
        </td>
      </tr>
    );
  }
}

export default Item;