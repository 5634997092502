export const TITLE_DEFAULT = 'Tin đăng';
export const TITLE = {
    new: 'Tin đăng hôm nay',
    today: 'Tin cập nhật hôm nay',
    check: 'Tin chờ kiểm duyệt',
    warning: 'Tin bị cảnh báo',
    deleted: 'Tin đã xóa',
    public: 'Tin đã duyệt',
    deny: 'Tin không duyệt'
};
export const STATUS = {
    delete: 'Đã xóa',
    pass: 'Đã duyệt',
    lock: 'Không duyệt',
    check:'Chờ duyệt'
}
