import React from 'react';

const Switch = function (props) {
    return (
        <div className="swGroup">
            <div className="switch">
                <input type="checkbox"
                    name={props.colName}
                    defaultChecked={props.status}
                    
                    onClick={(e) => props.click(e, props.code, props.apiObj, props.callBack, props.confirm_check)} />
                <span className="slider round" />
            </div>
            {
                props.label &&
                <label className="switchLabel">{props.label}</label>
            }
        </div>
    );
}
export default Switch;