import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import Sw from '../btn/Switch';
import globalLib from '../../utils';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.data
    }
  }

  callBack = (object) => {
     if (object.column==='status') {
        return swal('Status is changed successfully ...', { icon: "success",});
     }
     else{
        swal('Article is deleted successfully ...', { icon: "success",});
        this.props.callBack(object.id);
     }
  }

  render() {
    if (!this.state.item) return '';
    let item = this.state.item;
    return (
      <tr>
        <td>{item.id}</td>
        <td>
          <Link to={`/articles/${item.id}`}>
            <img alt="Hinh" src={ item.img_url + '/' + item.id + '.jpg?date='+item.modify_date} />
          </Link>
        </td>
        <td className="a-left">
          <h3>{item.title}</h3>
          <h4>{item.category}</h4>
          <h5>
            {item.description}
          </h5>
          <h5>
            <label>Ngày đăng</label> {item.post_date}
            <label>Ngày sửa</label> {item.modify_date}
          </h5>
        </td>
        <td>

        {
            // item.deleted === 'N' &&
            <Sw key={`S-${item.id}`}
              code={item.id}
              apiObj="articles"
              colName="status"
              status={item.status === 'A' ? true : false}
              click={globalLib.switchStatus}
              callBack={this.callBack}
            />
          }
        
        </td>

        <td>
          <Sw key={`D-${item.id}`}
            code={item.id}
            apiObj="articles"
            colName="deleted"
            status={item.deleted === 'Y' ? true : false}
            confirm_check="Are you sure you want to delete this article?"
            click={globalLib.switchStatus}
            callBack={this.callBack}
          />
        </td>

        <td>
          <Link to={`/articles/edit/${item.id}`}>
              <button className="btnEdit">Edit</button>
          </Link>
        </td>

        

      </tr>
    );
  }
}

export default Item;