import React, { Component } from 'react';
import { connect } from 'react-redux';
import globalLib from '../../utils';
import { loginUser } from '../../redux/actions/login';
import Sk from '../main/Sk';

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      processing: false,
      msg: ''
    }
  }



  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.processing) return;

    this.setState({ processing: true, msg: '' });
    globalLib.restApi({method:'post', uri:'login', data: this.state, notauth:true})
      .then(result => {
        this.props.userLogin(result.data.token);
      })
      .catch(err => {
        this.setState({ processing: false });
        globalLib.checkResponseError(err);
      });

    //
  }


  render() {
    return (

      <form className="f-login" onSubmit={this.handleSubmit}>
        <h2>User Login</h2>
        {
          this.state.processing ? <Sk /> : <hr />
        }
        <div>
          <p>
            <label>Username :</label>
            <input onChange={this.handleChange} type="text" name="username" />
          </p>
          <p>
            <label>Password : </label>
            <input onChange={this.handleChange} type="password" name="password" />
          </p>
        </div>

        <p>
          <input
            type="submit"
            name="btnLogin"
            defaultValue="Login"
            className="button"
          />
          <input
            type="reset"
            name="btnReset"
            defaultValue="Reset"
            className="button"
          />
        </p>
      </form>

    );
  }
}

const mapDispatchToProps = dispatch => ({
  userLogin: token => dispatch(loginUser(token))
})

export default connect(null, mapDispatchToProps)(Login);