import React from 'react';
import Logo from './Logo';
import Nav from './Nav';
import IconMenu from './Imenu';

const Header = function(){
    return (
        <header>
            <div>
                <Logo />
                <Nav />
                <IconMenu />
            </div>
        </header>
    );
}
export default Header;