import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import swal from 'sweetalert';

import globalLib from '../../utils';
import { listArticleCategories } from '../../redux/actions/category';
import Sk from '../main/Sk';

const initialArticle = {
    title:'',
    keywords:'',
    description:'',
    detail:'',
    photo:null,
    status:'',
    category_id:''
}

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: (this.props.article?this.props.article:initialArticle),
            categories: this.props.categories,
            processing: false
        }
    }

    setArticleData = (item, value) => {
        let article = {
            ...this.state.article, [item]: value
        };
        this.setState({ article });
    }

    onChangeHandle = (e) => {
        this.setArticleData(e.target.name, e.target.value);
    }

    getBase64 = (e) => {
        let file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setArticleData('photo', reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    showPhoto = () => {
        let img = this.state.article.photo;
        if (!img && this.state.article.img_url){
            img = this.state.article.img_url + this.state.article.id+'.jpg?date='+this.state.article.modify_date;
        }
        if(img) return <img src={img} alt="" />
    }

    Save = (e) => {
        e.preventDefault();

        if (this.state.processing) {
            return swal('Article is saving, please wait ...', { icon: "warning"});
        }

        let article = this.state.article;
        if(!article.title){
            return swal('Please enter artitle title', { icon: "warning"}).then(() => {
                document.getElementsByName('title')[0].focus();
            });
        }

        if(!article.keywords){
            return swal('Please enter artitle keywords', { icon: "warning"}).then(() => {
                document.getElementsByName('keywords')[0].focus();
            });
        }

        if(!article.photo && !article.id){
            return swal('Please select article photo', { icon: "warning"});
        }

        if(!article.description){
            return swal('Please enter artitle description', { icon: "warning"}).then(() => {
                document.getElementsByName('description')[0].focus();
            });
        }

        if(!article.detail){
            return swal('Please enter artitle content', { icon: "warning"}).then(() => {
                document.getElementsByName('detail')[0].focus();
            });
        }

        if(!article.category_id){
            return swal('Please select artitle category', { icon: "warning"}).then(() => {
                document.getElementsByName('category_id')[0].focus();
            });
        }

        if(!article.status){
            return swal('Please select artitle status', { icon: "warning"}).then(() => {
                document.getElementsByName('status')[0].focus();
            });
        }

        this.setState({ processing: true, msg: '' });
        globalLib.restApi({method:'post', uri:'articles/save', data: article, notauth:true})
        .then(result => {
            this.setState({ processing: false });
            if(!this.state.article.id){
                this.setState({ article: initialArticle });
                return swal('Artitle is updated successfully', { icon: "success"});
            }
            else{
                return swal('Artitle is inserted successfully', { icon: "success"});
            }
        })
        .catch(err => {
            this.setState({ processing: false });
            globalLib.checkResponseError(err);
        });
    }

    componentDidMount() {
        if (!this.state.categories) {
            this.props.getArticleCategories();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {};
        if (nextProps.categories !== prevState.categories) {
            state.categories = nextProps.categories;
        }
        return Object.keys(state).length ? state : null;
    }

    render() {

        return (
            <form>
                <div>
                    <input onChange={e => this.onChangeHandle(e)} name="title" placeholder="Title" value={this.state.article.title}/>
                </div>
                
                <div>
                    <input onChange={e => this.onChangeHandle(e)} name="keywords" placeholder="Keywords" value={this.state.article.keywords}/>
                </div>

                <div className="flex">
                    <div className="picture">
                        {
                            this.showPhoto()
                        }
                        <input type="file" onChange={e => this.getBase64(e)} />
                    </div>

                    <textarea onChange={e => this.onChangeHandle(e)} name="description" placeholder="Descrition" value={this.state.article.description}></textarea>

                </div>

                <div>
                    <CKEditor
                        editor={ClassicEditor}
                        data={this.state.article.detail}
                        onReady={editor => {
                            //console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setArticleData('detail', data);
                        }}
                        onBlur={(event, editor) => {
                             //console.log('Focus.', editor);
                        }}
                        onFocus={(event, editor) => {
                            //console.log('Focus.', editor);
                        }}
                        config={{
                            ckfinder: {uploadUrl: process.env.REACT_APP_API_SERVER + '/articles/upload' }
                        }}
                    />
                </div>

                <div>
                    <textarea name="detail" onBlur={e =>  this.onChangeHandle(e)} placeholder="Html source" defaultValue={this.state.article.detail}></textarea>
                </div>

                <div className="flex">
                    <select value={this.state.article.category_id} onChange={e => this.onChangeHandle(e)} name="category_id">
                        <option value="">Category</option>
                        {
                            this.state.categories &&
                            this.state.categories.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>
                            ))
                        }
                    </select>

                    <select value={this.state.article.status} onChange={e => this.onChangeHandle(e)} name="status">
                        <option value="">Status</option>
                        <option value='A'>Active</option>
                        <option value='I'>Inactive</option>
                        <option value='D'>Deleted</option>
                    </select>

                    
                    {
                        this.state.processing?
                        <Sk circle={true} w={30} h={30} />
                        :
                        <button onClick={(e) => this.Save(e)}>Save</button>
                    }

                </div>
                
            </form>
        );
    }
}

const mapStateToProps = (state) => ({
    categories: state.category.article_categories
});

const mapDistpatchToProps = (dispatch) => ({
    getArticleCategories: () => dispatch(listArticleCategories())
});

export default connect(mapStateToProps, mapDistpatchToProps)(Form);