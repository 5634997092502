import axios from 'axios';
import jwt from 'jsonwebtoken';
import swal from 'sweetalert';
import { store } from '../redux/store';
import { userLogout } from '../redux/actions/login';

class Lib {

  getToken = () => {
    return store.getState().login.token;
  }

  checkAuth = () => {
    try {
      let user = jwt.decode(this.getToken());
      const time = Date.now() / 1000;
      if (user.exp < time) {
        store.dispatch(userLogout());
      }
    } catch (error) {
      store.dispatch(userLogout());
    }
  }

  axiosHeader  = notAuth => {
     if(notAuth){
        return {'X-Requested-With': 'XMLHttpRequest'}
     }
     return { 'X-Requested-With': 'XMLHttpRequest', Authorization: `Bearer ${this.getToken()}` }
  }

  axiosOptions = params => {
    return {
      method: params.method,
      url: process.env.REACT_APP_API_SERVER + '/' + params.uri,
      data: params.data,
      headers: this.axiosHeader(params.notAuth),
      timeout: 200000
    }
  }

  restApi = params => axios(this.axiosOptions(params))

  checkResponseError = error => {
    if (error.response) {
      let status = error.response.status;
      if (status === 401) {
        return swal('You have to login', { icon: "warning"}).then(() => {
          this.logOut();
        });
        
      }
      else{  
        if(error.response.data.msg){
           //alert(error.response.data.msg);
           return swal(error.response.data.msg, { icon: "warning"});
        }
        else{
           //alert('Server has errors. Please contact administator!');
           return swal('Server has errors. Please contact administator!', { icon: "warning"});
        }
        //console.log('Error response => ', error.response);
      }
    }
    else if (error.request) {
      //alert('Can not connect to remote server. Please contact administator if your internet works well.');
      //console.log('Error request => ', error.request);
      return swal('Can not connect to remote server. Please contact administator if your internet works well.', { icon: "warning"});
    }
    else {
      //alert('There are some errors. Please refresh your browser and try again')
      //console.log('Error ', error);
      return swal('There are some errors. Please refresh your browser and try again', { icon: "warning"});
    }
  }

  logOut = () => {
    store.dispatch(userLogout())
  }

  //////////////////////////////


  switchStatus = (e, id, table_name, callBack, confirm_check) => {
    const object = e.target;

    if(confirm_check){
      swal({
        // title: "Are you sure?",
        text: confirm_check,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          this.doSwitch(id, object, table_name, callBack);
        }
        else{
          object.checked = !object.checked;
        }
      });
    }
    else{
      this.doSwitch(id, object, table_name, callBack);
    }
  }

  doSwitch = (id, object, table_name, callBack) => {
     
     this.restApi({
      method: 'post',
      uri: table_name + '/switch',
      data: {
        id: id,
        cl: object.name,
        vl: object.checked
      }
    })
      .then(result => {
        if (callBack) {
          callBack(result.data.info);
        }
      })
      .catch(err => {
        object.checked = !object.checked;
        this.checkResponseError(err);
      });
  }

}

export default new Lib()