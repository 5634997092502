import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../../images/logo.png';

const Logo = function(){
    return (
        <Link className="logo" to="/" title="nhà đất">
            <img src={logo} alt="nha dat can ban" />
            <span><strong title="nhà đất">nha dat</strong> can ban</span>
        </Link>
    );
}
export default Logo;