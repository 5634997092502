import React, { Component } from 'react';
import globalLib from '../../utils';
import Sk from '../main/Sk';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Picture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: null,
            id: this.props.id,
            reload: false
        }
    }
    
    componentDidMount() {
        this.getPictures();
    }
    componentDidUpdate() {
        if(this.state.reload){
            this.setState({reload: false});
            this.getPictures();
        }
    }

    showImgs = () => {
        if(this.state.items){
            return this.state.items.map((img, index) => {
                return (
                    // <div>
                        <img key={index} src={this.state.url + '/m/'+img.folder+'/'+img.img_name} alt="" />
                    // </div> 
                );
            });
        }   
        else{
            return <Sk/>
        }
    }

    getPictures = () => {
        globalLib.restApi({ method: 'get', uri: 'properties/pictures/'+this.state.id })
            .then(result => {
                this.setState({ 
                    items: result.data.pictures,
                    url: result.data.url  
                })
            })
            .catch(err => {
                globalLib.checkResponseError(err);
            })
    }
    render() {
        const settings = {
            autoplay:true,
            dots: true,
            infinite: true,
            speed: 500,
            // slidesToShow: 1,
            // slidesToScroll: 1
          };
        return (
            <div className="pictures">
                <Slider {...settings}>
                    {this.showImgs()}
                </Slider>
             </div>
        );
    }
}

export default Picture;