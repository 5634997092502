const socketReducer = (state = {socket:null, hide_online_users: false}, action) => {
    switch (action.type) {
      case 'STORE_SOCKET':
        return {...state, socket: action.socket}
      case 'HIDE_SOCKET_BAR':
        return {...state, status: action.status}  
      case 'COUNT_USERS':
          return {...state, counter: action.counter}
      case 'ONLINE_USERS':
        let counter = action.online_users?action.online_users.length:0;
        return {...state, online_users: action.online_users, counter}
      default:
        return state
    }
}
export default socketReducer;