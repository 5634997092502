import React, { Component } from 'react';
import { connect } from 'react-redux';
import QueryString from 'query-string';

// import '../../style/list.css';
import Item from './Item';
import Paging from '../paging';
import Head from './Head';
import Title from '../main/Title';
import SearchKey from '../main/SearchKey';
import globalLib from '../../utils';
import Sk from "../main/Sk";
import * as CUST from '../../const/customer';

class Customers extends Component {
    constructor(props) {
        super(props);
        let params = QueryString.parse(this.props.location.search);
        let page = params.page ? parseInt(params.page) : 1;

        this.state = {
            items: null,
            status: this.props.match.params.status,
            reload: false,
            counter: 0,
            page: page,
            keywords: this.props.match.params.keywords,
            search: false,
            total: 0
        }
    }

    render() {
        let st = this.state.status?this.state.status.replace(/-/g,'_'):'';
        let title = CUST.TITLE[st]?CUST.TITLE[st]:CUST.TITLE_DEFAULT;

        return (
            <main>
                <Title key="title-users" text={title} tool={
                    <SearchKey key="search-users"
                        onEnter = {this.onEnter}
                        onChange = {this.onChange}
                        refresh={this.Load}
                        reset={this.SearchReset}
                        defaultValue={this.state.keywords} />} />
                {
                    this.state.items ?
                        (
                            <div className="content">
                                <table>
                                    <Head />
                                    <tbody>
                                        {this.showItems()}
                                    </tbody>
                                </table>
                                <Paging key={`customer-paging`}
                                    total={this.state.total}
                                    page={this.state.search ? 1 : this.state.page}
                                    page_size={20}
                                    uri={this.state.status ? `customers/${this.state.status}` : `customers`} />
                            </div>
                        )
                        :
                        <Sk />
                }

            </main>
        );
    }

    Load = () => {
        this.setState({ items: null, reload: false }, () => this.getUsers());
    }

    SearchReset = (e) => {
        this.props.match.params.keywords = null;
        this.setState({ keywords: ''}, () => this.startSearch());
    }

    startSearch = () => {
        this.setState({ search: true, items: null, reload: false }, () => this.getUsers());
    }

    onChange = (e) => {
        this.setState({ keywords:  e.target.value });
    }

    onEnter = (e) => {
        if (e.which === 13) {
            this.setState({ keywords:  e.target.value }, () => this.startSearch());
        }
    }

    onLock = (object) => {
        if(object.value){
           this.props.socket.emit('lock_user', object.id);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let st = nextProps.match.params.status;
        if (st !== prevState.status) {
            return { status: st, reload: true, items: null }
        }

        let kw = nextProps.match.params.keywords;
        if (kw && kw !== prevState.keywords) {
            return { keywords: kw, reload: true, items: null }
        }

        let params = QueryString.parse(nextProps.location.search);
        let page = params.page ? parseInt(params.page) : 1;

        if (page !== prevState.page) {
            return { page, reload: true, items: null }
        }

        return null;
    }

    componentDidUpdate() {
        if (this.state.reload) {
            this.Load();
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    showItems = () => {
        return this.state.items.map((item, index) => {
            item.img_url = this.state.img_url;
            return <Item key={index} data={item} callBack={this.onLock} />
        });
    }

    getUsers = () => {
        let data = {
            page: this.state.search ? 1 : this.state.page,
            status: this.state.status,
            keywords: this.state.keywords
        };
        
        globalLib.restApi({ method: 'post', uri: 'users', data })
            .then(result => {
                this.setState({ total: result.data.total, items: result.data.items, reload: false, counter: 0 });
                if (this.state.search) {
                    this.props.location.search = {};
                    this.setState({ search: false, page: 1 });
                    let uri = '/customers';
                    if (this.state.status) {
                        uri += '/' + this.state.status;
                    }
                    window.history.pushState({}, null, uri);
                }
            })
            .catch(err => {
                globalLib.checkResponseError(err);
                this.setState({ items: [], reload: false });
            });
    }
}


const mapStateToProps = state => ({
    socket: state.socket.socket
});

export default connect(mapStateToProps)(Customers);