import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Sw from '../btn/Switch';
import globalLib from '../../utils';
import { STATUS } from '../../const/property';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.data
    }
  }


  callBack = (object) => {

    let temp = this.state.item;
    if (object.column === 'status') {
      temp.status = (object.value) ? STATUS.lock : STATUS.pass;
      temp.gui = (object.value) ? 'false' : 'pass';
    }
    else if (object.column === 'deleted') {
      temp.deleted = 'N';
      if (object.value) {
        temp.gui = 'hot';
        temp.status = STATUS.delete;
        temp.deleted = 'Y';
      }
      else if (temp.status_id === '3') {
        temp.gui = 'false';
        temp.status = STATUS.lock;
      }
      else if (temp.status_id === '2') {
        temp.gui = 'pass';
        temp.status = STATUS.pass;
      }
      else {
        temp.gui = 'hot';
        temp.status = STATUS.check;
      }
    }
    this.setState({ item: temp })
  }

  render() {
    if (!this.state.item) return '';
    let item = this.state.item;
    return (
      <tr>
        <td>{item.id}</td>
        <td>
          <Link to={`/properties/${item.id}/${item.user_id}`}>
            <img alt="Hinh" src={item.img_url + '/' + item.picture} />
          </Link>
        </td>
        <td className="a-left">
          <h3>{item.title}</h3>
          <h4>
            <span className="price">
              <label>Giá {item.transaction}: </label>{item.price}
            </span>
            <span>
              {item.dtsd} m2
            </span>
            <span>
              <label>VT: </label>{item.district}, {item.city}
            </span>
          </h4>
          <h5>
            <label>Ngày đăng</label> {item.post_date}
            <label>Người đăng</label> {item.user.name}

          </h5>
          <h5>
            <label>Ngày sửa</label> {item.modify_date}
            <label>Điện thoại</label> <Link to={`/customers/keywords/${item.user.phone}`}>{item.user.phone}</Link>
          </h5>
        </td>
        <td className={item.gui}>{item.status}</td>
        <td>
          {
            item.deleted === 'N' &&
            <Sw key={`P-${item.id}`}
              code={item.id}
              apiObj="properties"
              colName="public"
              status={item.public === 'I' ? true : false}
              click={globalLib.switchStatus}
              
            />
          }
        </td>
        <td>
          {
            item.deleted === 'N' &&
            <Sw key={`S-${item.id}`}
              code={item.id}
              apiObj="properties"
              colName="status"
              status={item.status_id === '3' ? true : false}
              click={globalLib.switchStatus}
              callBack={this.callBack}
            />
          }
        </td>

        <td>
          <Sw key={`D-${item.id}`}
            code={item.id}
            apiObj="properties"
            colName="deleted"
            status={item.deleted === 'Y' ? true : false}
            click={globalLib.switchStatus}
            callBack={this.callBack}
          />
        </td>

      </tr>
    );
  }
}

export default Item;