import React from 'react';
import {useSelector} from 'react-redux';
import '../css/style.css';
import Main from './main';
import Login from './auth/Login';

export default function App(){

    return (
       useSelector(state => state.login.isLogin) ? <Main /> : <Login />
    );
}