import React, { Component } from 'react';

class Head extends Component {
    render() {
        return (
           <thead>
            <tr>
                <th className="w-50 a-center">ID</th>
                <th>Name</th>
                <th>Post date</th>
                <th>Modify date</th>
                <th className="w-60 a-center">Active</th>
                <th className="w-40 a-center">Edit</th>
            </tr>
            </thead> 
        );
    }
}

export default Head;