import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Item extends Component {
    
    render() {
        let item = this.props.data;
        return (
            <li id={item.id}>
                <Link to={
                    {
                        pathname: `/properties/${item.id}/${item.user_id}`
                    }
                }>
                    <img alt="Hinh" src={item.img_url + '/' + item.picture} />
                </Link>

                <h4>{item.title}</h4>
                <div>
                    <label>{item.transaction}</label>
                    <span>{item.price}</span>
                    <span>{item.dtsd}m²</span>
                    <em>{item.district}, {item.city}</em>
                </div>
                <p className={item.gui}>
                    <label> Status</label> {item.status}
                </p>
                <p>
                    <label> Post</label> {item.post_date}
                </p>
                <p>
                    <label>Modify</label> {item.modify_date}
                </p>
            </li>
        );
    }
}

export default Item;