import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../redux/actions/login';
import { hideSocketbar} from '../../redux/actions/socket';

const Logout = function() {
  let status = !useSelector(state => state.socket.status);
  let counter = useSelector(state => state.socket.counter);
  const dispatch = useDispatch();
  return (
      <ul>
        <li>
          <a href="/logout" onClick={(e)=> {
                e.preventDefault(); 
                dispatch(hideSocketbar(status)); 
              }
            }>Members ({counter})</a>
        </li>
        <li>
          <a href="/logout" onClick={(e)=> {
                e.preventDefault(); 
                dispatch(userLogout());
              }
            }>Logout</a>
        </li>
      </ul>
  );
}
export default Logout;