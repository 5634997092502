import React, { Component } from 'react';
// import '../../style/title.css';

class Title extends Component {
    render() {
        return (
            <hgroup>
                <h1>{this.props.text}</h1>
                {this.props.tool}
            </hgroup>
        );
    }
}

export default Title;