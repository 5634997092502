import globalLib from '../../utils';
import {LIST_CITIES} from '../../const';

export const cities = cities => {
  return {
    type: LIST_CITIES,
    cities
  }
}

export const listCities = () => {
  return dispatch => {
    globalLib.restApi({ method: 'get', uri: 'location/cities' })
      .then(result => {
            dispatch(cities(result.data));
      })
      .catch(err => {
         globalLib.checkResponseError(err);
      });
  };
}