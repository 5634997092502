import React from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Sk = function Sk(props){
    let count = props.count?props.count:1;
    if(props.circle){
        return (
            <SkeletonTheme highlightColor="red" color="yellow">
                <Skeleton circle={true} height={props.h} width={props.w}/>
            </SkeletonTheme>
        )
    }
    return (
        <SkeletonTheme highlightColor="black" color="#fff">
            <Skeleton height={2} duration={1} count={count}/>
        </SkeletonTheme>
    )
}
export default Sk;