import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import { storeSocket, userCounter } from '../../redux/actions/socket';

class OnlineUsers extends Component {
    
    constructor(props) {
        super(props);
        this.state = {users: null};
        this.socket = null;
    }

    // countUser = (users) => {
    //     return users?users.length:0;
    // }

    // componentDidMount() {
    //     console.log('counter: ',this.props.counter);
    //     if(!this.props.socket){
    //         const opts = {
    //             transports: [process.env.REACT_APP_TRANSFERS]
    //         }
    //         this.socket = io(process.env.REACT_APP_SOCKET_SERVER, opts);
    //         this.props.storeSocket(this.socket);
    //     }
    //     else{
    //         this.socket = this.props.socket;
    //     }
        
    //     const token  = `Bearer ${this.props.token}`;

    //     this.socket.emit('list_users', {token, msg: 'Hello'});

    //     this.socket.on('connect', () => {
    //         this.socket.emit('admin_connect', {token, msg: 'Hello'});
    //     });
        
    //     this.socket.on('online_users', users => {
    //         this.setState({users});
    //         this.props.userCounter(this.countUser(users));
    //     });

    //     this.socket.on('new_connected_user', user => {
    //         let users = this.state.users;
    //         users = !users?[]:users;
    //         users.unshift(user);
    //         this.setState({users});
    //         this.props.userCounter(this.countUser(users));
    //     });

    //     this.socket.on('disconnected_user', socket_id => {
    //         let users = this.state.users;
    //         users = users.filter(u => u.socket_id !== socket_id);
    //         this.setState({users});
    //         this.props.userCounter(this.countUser(users));
    //     });

    //     this.socket.on('disconnected_room', user_id => {
    //         let users = this.state.users;
    //         users = users.filter(u => parseInt(u.user_id) !== parseInt(user_id));
    //         this.setState({users});
    //         this.props.userCounter(this.countUser(users));
    //     });
        
    // }

    // lockUser = (socket_id) => {
    //     this.socket.emit('lock_user', socket_id);
    // }

    countUser = (users) => {
        return users?users.length:0;
    }

    componentDidMount() {

        if(!this.props.socket){
            const opts = {
                transports: [process.env.REACT_APP_TRANSFERS]
            }
            this.socket = io(process.env.REACT_APP_SOCKET_SERVER, opts);
            this.props.storeSocket(this.socket);
        }
        else{
            this.socket = this.props.socket;
        }
        
        const token  = `Bearer ${this.props.token}`;

        this.socket.on('connect', () => {
            console.log('Connected');
            this.socket.emit('admin_connect', {token, msg: 'Hello'});
        });
        
        this.socket.on('online_users', users => {
            console.log('Users: ', users);
            this.setState({users});
            this.props.userCounter(this.countUser(users));
        });

        this.socket.on('new_connected_user', user => {
            console.log('Hello');
            let users = this.state.users;
            users = !users?[]:users;
            users.unshift(user);
            this.setState({users});
            this.props.userCounter(this.countUser(users));
        });

        this.socket.on('disconnected_user', socket_id => {
            console.log('Disconnected: ', socket_id);
            let users = this.state.users;
            users = users.filter(u => u.socket_id !== socket_id);
            this.setState({users});
            this.props.userCounter(this.countUser(users));
        });

        this.socket.on('disconnected_room', user_id => {
            let users = this.state.users;
            users = users.filter(u => parseInt(u.user_id) !== parseInt(user_id));
            this.setState({users});
            this.props.userCounter(this.countUser(users));
        });
        
    }

    lockUser = (socket_id) => {
        this.socket.emit('lock_user', socket_id);
    }
    
    render() {
        if(!this.props.socket_status) return <></>
        return (
            <div className="leftSidebar">
                <ul>
                    {
                        this.state.users &&
                        this.state.users.map((user, index) => (
                            <li key={index}>
                                <Link to={`/customers/keywords/${user.phone}`}>{user.phone}</Link>
                                <Link to={`/properties/customer/${user.user_id}`}>{user.name}</Link>
                                <span>{user.user_id} - {user.ip} - {user.time}</span>
                            </li>
                        ))
                    }
                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    token: state.login.token,
    socket: state.socket.socket,
    socket_status: state.socket.status,
    counter: state.socket.counter
});

const mapDispatchToProps = dispatch => ({
    storeSocket: socket => dispatch(storeSocket(socket)),
    userCounter: counter => dispatch(userCounter(counter))
});

export default connect(mapStateToProps, mapDispatchToProps)(OnlineUsers);