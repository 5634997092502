import React, { Component } from 'react';

class Head extends Component {
    render() {
        return (
           <thead>
            <tr>
                <th className="w-50 a-center">ID</th>
                <th className="w-200 a-center">Picture</th>
                <th className="a-left">Description</th>
                <th>Status</th>
                <th className="w-50 a-center">Warned</th>
                <th className="w-50 a-center">Blocked</th>               
                <th className="w-50 a-center">Deleted</th>
            </tr>
            </thead> 
        );
    }
}

export default Head;