import globalLib from '../../utils';
import {CLEAR_RELATED_PROPERTIES, RELATED_PROPERTIES, INFO_PROPERTY} from '../../const';

export const clearProperties = () => {
  return {
    type: CLEAR_RELATED_PROPERTIES,
    properties: null
  }
}

export const related_properties = properties => {
  return {
    type: RELATED_PROPERTIES,
    properties: properties
  }
}


export const info_property = property => {
  return {
    type: INFO_PROPERTY,
    info_property: property
  }
}

export const relatedProperties = (user_id, page=1) => {
  return dispatch => {
    globalLib.restApi({ method: 'post', uri: 'properties', data:{user_id, page} })
      .then(result => {
        let properties = { total: result.data.total, items: result.data.items, img_url: result.data.img_url };
        dispatch(related_properties(properties));
      })
      .catch(err => {
          globalLib.checkResponseError(err);
      });
  };
}


export const infoProperty = property_id => {
  return dispatch => {
    globalLib.restApi({ method: 'get', uri: 'properties/' + property_id })
      .then(result => {
        dispatch(info_property(result.data.info));
      })
      .catch(err => {
         globalLib.checkResponseError(err)
      });
  };
}
