import React, { Component } from 'react';
import {connect} from 'react-redux';
import {listCities} from '../../redux/actions/location';
import {listCategories} from '../../redux/actions/category';
// import '../../style/search.css';

import globalLib from '../../utils';

class SearchKey extends Component {

    initParams = {
        keywords: '',
        city: '',
        district: '',
        transaction: '',
        category: ''
    }

    constructor(props) {
        super(props);
        this.state = { ...this.initParams,
            cities: this.props.cities,
            categories: this.props.categories
        }
    }
    
    componentDidMount(){
        if(!this.state.cities){
            this.props.getCities();
        }
        if(!this.state.categories){
            this.props.getCategories();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let state = {};
        if(nextProps.cities!==prevState.cities){
            state.cities = nextProps.cities;
        }
        if(nextProps.categories!==prevState.categories){
            state.categories = nextProps.categories;
        }
        return Object.keys(state).length?state:null;
    }

    handleChange = e => {
        var name = e.target.id;
        if(name === 'city'){
            this.setState({ [name] : e.target.value, district:'' }, () => this.loadDistricts());
        }
        else{
            this.setState({ [name] : e.target.value});
        }
    }

    loadDistricts(){
       
        if(this.state.city==='' || isNaN(this.state.city)) {
            return;
        }
        globalLib.restApi({ method: 'get', uri:'location/districts/'+this.state.city })
            .then(result => {
                this.setState({ districts: result.data })
            })
            .catch(err => {
                globalLib.checkResponseError(err);
            });
    }

    onSearch = () => {
        let params = {
            keywords: this.state.keywords,
            city: this.state.city,
            district: this.state.district,
            transaction: this.state.transaction,
            category: this.state.category
        }
        this.props.onSearch(params);
    }

    onEnter = (e) => {
        if (e.which === 13) {
            this.onSearch();
        }
    }

    onReset = () => {
        let params = this.initParams;
        this.setState(params, () => {
            this.onSearch();
        });
    }

    render() {
        return (
            <div className="search">
                <input type="text" id="keywords"
                    onKeyUp ={e => this.onEnter(e)}
                    onChange={e => this.handleChange(e)}
                    // value={this.props.defaultValue} 
                    value={this.state.keywords}
                    placeholder = "Keywords ..."
                />

                <select id="city" value={this.state.city} onChange={e => this.handleChange(e)}>
                    <option value="">City</option>
                    {
                        this.state.cities &&
                        this.state.cities.map((city, index) => (
                            <option key={index} value={city.city_id}>{city.city_name}</option>
                        ))
                    }
                </select>

                <select id="district" value={this.state.district} onChange={e => this.handleChange(e)}>
                    <option value="">District</option>
                    {
                        this.state.districts &&
                        this.state.districts.map((district, index) => (
                            <option key={index} value={district.district_id}>{district.district_name}</option>
                        ))
                    }
                </select>

                <select value={this.state.category} onChange={e => this.handleChange(e)} id="category" dangerouslySetInnerHTML={{__html: `<option value="">Category</option>  ${this.state.categories}`}} />

                <select value={this.state.transaction} onChange={e => this.handleChange(e)} id="transaction">
                    <option value="">Transactions</option>
                    <option value="s">Bán</option>
                    <option value="r">Cho thuê</option>
                </select>

                <button onClick={() => this.onSearch()}> Search </button>    
                <button onClick={() => this.onReset()}> Reset </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cities: state.location.cities,
    categories: state.category.categories
});

const mapDistpatchToProps = (dispatch) => ({
    getCities: () => dispatch(listCities()),
    getCategories: () => dispatch(listCategories())
});


export default connect(mapStateToProps, mapDistpatchToProps)(SearchKey);