import React, { Component } from 'react';

import Title from '../main/Title';
// import '../../style/cache.css';
import globalLib from '../../utils';

class Cache extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cache_key:'',
            user_id:''
        }

    }
    cacheKeyChage = e => {
        this.setState({cache_key: e.target.value});
    }
    userIdChange = e => {
        if(isNaN(e.target.value)){
            alert('User ID must be nummeric');
            return;
        }
        this.setState({user_id: e.target.value});
    }
    clearCache = () => {
        if(this.state.cache_key===''){
            alert('Please enter cache key');
        }
        else{
            globalLib.restApi({ method: 'patch', uri: 'cache/clear-cache', data:{key: this.state.cache_key} })
            .then(result => {
                alert('Clear cache successfully');
            })
            .catch(err => {
                globalLib.checkResponseError(err);
            })
        }
    }

    clearDeletedProperties = () => {
        if(window.confirm('Are you sure you want to clear deleted properties?')){
            globalLib.restApi({ method: 'patch', uri: 'cache/clear-deleted-properties' })
            .then(result => {
                alert('Clear deleted properties successfully');
            })
            .catch(err => {
                globalLib.checkResponseError(err);
            })
        }
    }

    clearUserProperties = () => {
        let id = this.state.user_id;
        if(isNaN(id) || id===''){
            alert('User id must be nummeric');
            return;
        }
        if(window.confirm('Are you sure you want to clear properties of user '+id+'?')){
            globalLib.restApi({ method: 'patch', uri: 'cache/clear-user-properties', data:{id} })
            .then(result => {
                alert('Clear user properties successfully');
            })
            .catch(err => {
                globalLib.checkResponseError(err);
            })
        }
    }

    render() {
        return (
            <main>
                <Title text="Cache Manager" />
                <div className="content cache">
                    <table>
                        <thead>
                            <tr>
                                <th className="w-200">Name</th>
                                <th>Content</th>
                                <th className="w-100 a-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Data caching
                               </td>
                                <td>
                                    <input type="text" id="txtCache" onChange={(e) => this.cacheKeyChage(e)}/>
                                </td>
                                <td>
                                    <button onClick={() => this.clearCache()}>Clean</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Deleted properties
                               </td>
                                <td>
                                    Empty deleted properties
                                </td>
                                <td>
                                    <button onClick={() => this.clearDeletedProperties()}>Clean</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Properties of user 
                               </td>
                               <td>
                                    <input type="text" id="txtUser" onChange={(e) => this.userIdChange(e)}/>
                                </td>
                                <td>
                                    <button onClick={() => this.clearUserProperties()}>Clean</button>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </main>
        );
    }
}

export default Cache;