import React, { Component } from 'react';
import Sw from '../btn/Switch';
import globalLib from '../../utils';

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {processing: false, data: this.props.data }
  }

  
  render() {
    let data = this.state.data;
    return (
        <tr>
          <td className="a-center">{data.id}</td>
          <td className="text-cap">
              {data.name}
          </td>
          <td>
            {data.post_date}
          </td>
          <td>
            {data.modify_date}
          </td>
          <td>
          {
            <Sw key={`S-${data.id}`}
              code={data.id}
              apiObj="categories"
              colName="status"
              status={data.status === 'A' ? true : false}
              click={globalLib.switchStatus}
              callBack={this.props.callBack}
            />
          }
          </td>
          <td>
            <button className="btnEdit" onClick={() => this.props.edit(data)}>Edit</button>
          </td>
        </tr>
    )
  }
}


export default Item;