import React, { Component } from 'react';
import Detail from './Detail';
import Relation from './Relation';
import User from './User';

class PropertyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            id: this.props.match.params.id,
            user_id: this.props.match.params.user_id
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.id !== prevState.id) {
            return {
                id: nextProps.match.params.id
            }
        }
        return null;
    }

    render() {
        return (
            <main>
                <User user_id = {this.state.user_id}/>
                <div className="content table">
                    <Detail key={this.state.id}
                        getUser={this.getUser}
                        id={this.state.id}
                    />
                    <aside>
                        <Relation user_id={this.state.user_id} />
                    </aside>
                </div>
            </main>
        );
    }
}

export default PropertyDetail;