import React, { Component } from 'react';
import Title from '../main/Title';
import Form from './Form';
class Add extends Component {
    render() {
        return (
            <main>
                <Title text="New article" />
                <Form />
            </main>
        );
    }
}

export default Add;