//import axios from 'axios';
import {LOGIN_USER, LOGOUT_USER} from '../../const';

export const userLogout = () => ({
   type: LOGOUT_USER
})


export const loginUser = token => ({
  type: LOGIN_USER,
  token: token,
  isLogin: true
})

// export const userLoginAxios = user => {
//    return dispatch => {
//       return axios.post('http://localhost:9999/login', user)
//       .then(result => {
//           if (result.status === 200) {
//             let token = result.data.token;
//             localStorage.setItem("token", token)
//             dispatch(loginUser(token))
//           }
          
//       });
//   };
// }



  
// export const userLoginFetch = user => {
//   return dispatch => {
//       return fetch("http://localhost:9999/login", {
//         method: "POST",
//         headers: {
//           'Content-Type': 'application/json',
//           Accept: 'application/json',
//         },
//         body: JSON.stringify(user)
//       })
//       .then(resp => resp.json())
//       .then(data => {
//         if (data.status == 200) {
//           localStorage.setItem("token", data.token)
//           dispatch(loginUser(data.token))
//         }
//       })
//     }
// }