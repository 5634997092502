import { Component } from 'react';
// import { connect } from 'react-redux';
// import { listArticleCategories } from '../../redux/actions/category';

import globalLib from '../../utils';
import swal from 'sweetalert';

class Base extends Component{

    add = () => {
        this.setState({  update: true, item: {name: '', id: ''} });
    }

    edit = (data) => {
        this.setState({  update: true, item: data });
        
    } 

    cancel = () => {
        this.setState({ update: false, processing: false });
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    }

    //
    save = (e) => {
        e.preventDefault();
        if (this.state.processing) {
          return swal('Article category is saving, please wait ...', { icon: "warning"});
        }
        let data = {
          name: this.state.name,
          id: this.state.item.id
        }
        this.setState({ processing: true});
    
        globalLib.restApi({ method: 'post', uri: 'categories/save', data })
          .then(result => {
            let items = this.state.items;
            if(data.id!==''){   
                let objIndex = items.findIndex((obj => obj.id === data.id));
                items[objIndex].name = data.name;
            }
            else{
                items.push({
                    name: data.name, 
                    id: result.data.id, 
                    status:'A', 
                    post_date: result.data.date,
                    modify_date: result.data.date
                });
            }

            this.setState({ processing: false, items });
            this.saveCategories(items);
            this.cancel();

            return swal(result.data.msg, { icon: "success"});
          })
          .catch(err => {
             globalLib.checkResponseError(err);
          })
      }
}
export default Base;


// const mapStateToProps = (state) => ({
//     categories: state.category.article_categories
// });

// const mapDistpatchToProps = (dispatch) => ({
//     getArticleCategories: () => dispatch(listArticleCategories())
// });

// export default connect(mapStateToProps, mapDistpatchToProps)(Base);