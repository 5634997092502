export const MENU = [
    {title: 'Tin đăng', path: '/properties', child: [
        {title: 'Tin hôm nay', path: '/properties/today'},
        {title: 'Tin mới đăng', path: '/properties/new'},
        {title: 'Tin chờ duyệt', path: '/properties/check'},
        {title: 'Tin đã duyệt', path: '/properties/public'},
        {title: 'Tin cảnh báo', path: '/properties/warning'},
        {title: 'Tin không duyệt', path: '/properties/deny'},
        {title: 'Tin bị xóa', path: '/properties/delete'}]
    },
    {title: 'Khách hàng', path: '/customers', child: [
        {title: 'Đã đăng nhập', path: '/customers/login'},
        {title: 'Đăng nhập hôm nay', path: '/customers/login-today'},
        {title: 'Đăng ký hôm nay', path: '/customers/register-today'},
        {title: 'Bị kiểm tra robot', path: '/customers/warning'},
        {title: 'Bị khóa tài khoản', path: '/customers/blocked'},
        {title: 'Tài khoản đang active', path: '/customers/active'}]
    },
    {title: 'Bài viết', path: '/articles', child: [
        {title: 'Danh sách bài viết', path: '/articles'},
        {title: 'Thêm bài viết', path: '/articles/add'},
        {title: 'Danh mục bài viết', path: '/categories'},
        {title: 'Danh sách links', path: '/links'}]
    },
    {title: 'Cache', path: '/cache'},
    {title: 'Setup', path: '/setup'},
];
